import * as S from "./styles";

export default function PacmanLoader() {
  return (
    <S.Wrapper>
      <S.Loader>
        <S.Circles>
          <S.One />
          <S.Two />
          <S.Three />
        </S.Circles>
        <S.Pacman>
          <S.Top />
          <S.Bottom />
          <S.Eye />
        </S.Pacman>
      </S.Loader>
    </S.Wrapper>
  );
}
