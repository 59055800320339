import styled, { keyframes } from "styled-components";

export const Wrapper = styled.div`
  position: relative;
  height: 100vh;
  margin-top: "-3.55rem";
  width: 100vw;
  background: #fff;
`;

export const Loader = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  height: 60px;
  width: 160px;
  margin: 0;
  transform: translate(-50%, -50%);
`;

const animcircles = keyframes`{
    0% {
      transform: translate(0px, 0px);
    }
    100% {
      transform: translate(-40px, 0px);
    }
  }`;

const animtop = keyframes`{
    0%,
    100% {
      transform: rotate(0deg);
    }
    50% {
      transform: rotate(-45deg);
    }
  }`;

const animbottom = keyframes`{
    0%,
    100% {
      transform: rotate(0deg);
    }
    50% {
      transform: rotate(45deg);
    }
  }`;

export const Circles = styled.div`
  position: absolute;
  left: -5px;
  top: 0;
  height: 60px;
  width: 180px;
  animation: ${animcircles} 0.5s infinite linear;

  span {
    position: absolute;
    top: 25px;
    height: 12px;
    width: 12px;
    border-radius: 12px;
    background-color: #eee;
  }
`;

export const One = styled.span`
  right: 80px;
`;
export const Two = styled.span`
  right: 40px;
`;
export const Three = styled.span`
  right: 0px;
`;

export const Pacman = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  height: 60px;
  width: 60px;
  span {
    position: absolute;
    top: 0;
    left: 0;
    height: 60px;
    width: 60px;
    &::before {
      content: "";
      position: absolute;
      left: 0;
      height: 30px;
      width: 60px;
      background-color: #eee;
    }
  }
`;

export const Top = styled.span`
  animation: ${animtop} 0.5s infinite;
  &::before {
    top: 0;
    border-radius: 60px 60px 0px 0px;
  }
`;

export const Bottom = styled.span`
  animation: ${animbottom} 0.5s infinite;
  &::before {
    bottom: 0;
    border-radius: 0px 0px 60px 60px;
  }
`;

export const Eye = styled.div`
  position: absolute;
  top: 10px;
  left: 30px;
  height: 7px;
  width: 7px;
  border-radius: 7px;
  background-color: #fff;
`;
